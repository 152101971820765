.auth-pages {
    margin: 100px auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 100px;
    min-height: 60vh;

    input {
        border: 1px solid $text-color-light;
        padding: 16px 10px;
        border-radius: 5px;
        width: 100%;
        font-size: 14px;
    }

    input[type="checkbox"] {
        width: auto;
    }

    .login-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .auth-button {
        background: $text-color;
        color: $white;
        border-radius: 5px;
        padding: 12px 50px;

        &:hover {
            background: lighten($text-color, 10%);
        }
    }

    .auth-button-hollow {
        background: white;
        color: $text-color;
        border-radius: 5px;
        border: 1px solid $text-color;
        padding: 12px 50px;

        &:hover {
            background: $text-color;
            color: $white;
        }
    }

    .auth-right {
        border-left: 1px solid $separator-color;
        padding-left: 100px;
    }

    .already-have-container {
        text-align: right;
    }

}
