html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

a {
    text-decoration: none;
    color: $text-color;
    &:visited {
        color: $text-color;
    }
    &:hover {
        color: lighten($text-color, 40%);
    }
}

.logo a {
  /* color: #e9e9e9; */
  color: #212121;
   width: 200px;
}

h1 {
    margin-bottom: 40px;
}

h2 {
    margin-bottom: 10px;
}

img {
    max-width: 100%;
}