.cart-section {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 30px;
    margin: 60px auto;

    h2 {
        margin-bottom: 30px;
    }

    .cart-table {

    }

    .cart-table-row {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid $text-color-light;
        padding: 14px 0;

        &:last-child {
            border-bottom: 1px solid $text-color-light;
        }

        .cart-table-row-left, .cart-table-row-right {
            display: flex;
            justify-content: space-between;
        }

        .cart-table-row-left {
            width: 50%;
        }

        .cart-table-row-right {
            width: 33%;
            padding-top: 10px;
        }

        .cart-table-img {
            max-height: 75px;
        }

        .cart-table-actions {
            text-align: right;
            font-size: 14px;
        }

        .cart-item-details {
            padding-top: 5px;
            min-width: 250px;
            max-width: 250px;
        }

        .cart-table-description {
            color: $text-color-light;
        }

        .cart-options {
            color: $text-color;
            background: transparent;
            font-size: 14px;
            font-weight: 300;
            padding: 0;

            &:hover {
                color: lighten($text-color, 30%);
            }
        }
    }

    .cart-totals {
        margin: 30px 0;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        background: $light-gray-background;

        .cart-totals-left {
            width: 50%;
        }

        .cart-totals-right {
            display: flex;
            justify-content: space-between;
            width: 35%;
            text-align: right;
        }

        .cart-totals-total {
            font-weight: bold;
            font-size: 22px;
            line-height: 2;
        }
    }

    .cart-buttons {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;
    }

    .have-code {
        display: block;
        margin: 14px 0;
        text-align: right;
    }

  .have-code-container {
    border: 1px solid $text-color-light;
    padding: 16px;
    width: 50%;
    margin-left: auto;

    form {
      display: flex;
      justify-content: space-between;
    }

    input[type="text"] {
      width: 70%;
      padding: 10px;
      font-size: 16px;
    }

    input[type="submit"] {
      background: white;
      border: 1px solid $text-color-light;
      padding-top: 8px;
      padding-bottom: 8px;

      &:hover {
        background: $text-color;
      }
    }
  }
}






