.sidebar {
    h3 {
        font-weight: bold;
        margin-bottom: 16px;
    }

    ul {
        line-height: 2;
        margin-bottom: 20px;
    }
}
