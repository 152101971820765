@charset 'utf-8';

// Abstracts
@import 'abstracts/variables';

// Base
@import 'base/reset';
@import 'base/base';
@import 'base/helpers';
@import 'base/typography';
@import 'base/utility';

// Components
@import 'components/breadcrumbs';
@import 'components/buttons';
@import 'components/section-description';
@import 'components/sidebar';
@import 'components/might-like';
@import 'components/form';
@import 'components/alerts';
@import 'components/pagination';
@import 'components/search';
@import 'components/tables';
@import 'components/badge';

// Layout
@import 'layout/header';
@import 'layout/footer';

// Pages
@import 'pages/shop';
@import 'pages/product';
@import 'pages/cart';
@import 'pages/checkout';
@import 'pages/thankyou';
@import 'pages/search-results';
@import 'pages/search-results-algolia';
@import 'pages/auth';
@import 'pages/my-profile';
@import 'pages/my-orders';
@import '~admin-lte/plugins/select2/css/select2.min.css';
@import '~admin-lte/plugins/select2-bootstrap4-theme/select2-bootstrap4.min.css';

.menu-pdv {
    margin-top: 13px;
}

.cart-section .have-code-container {
    width: 60%;
}

.select2-container .select2-selection--single {
    height: 48px !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 45px !important;
}

.select2 .select2-container .select2-container--default {
    width: 100% !important;
}

.is-invalid {
    .select2-container--default .select2-selection {
        border: 1px solid #dc3545 !important;
    }
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 45px;
}

.open-button {
    background-color: #555;
    color: white;
    padding: 16px 20px;
    border: none;
    cursor: pointer;
    opacity: 0.8;
    position: fixed;
    bottom: 23px;
    right: 28px;
    width: 280px;
}

.popup {
    display: none;
    position: fixed;
    bottom: 0;
    right: 15px;
    border: 3px solid #f1f1f1;
    z-index: 9;
}

.popup-container {
    max-width: 300px;
    padding: 10px;
    background-color: white;
}

.popup-container .btn {
    background-color: #04AA6D;
    color: white;
    padding: 16px 20px;
    border: none;
    cursor: pointer;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 20px;
    opacity: 0.8;
    cursor: pointer;
}

.popup-container .privacy-policy {
    cursor: pointer;
    text-decoration: underline;
    color: #04AA6D;
}

.wp-float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

.icon-wp-float {
    margin-top: 16px;
}