body {
    font-family: 'Roboto', Arial, sans-serif;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.6;
}

h1, h2, .product-section-subtitle, .product-section-price {
    font-family: 'Montserrat', Arial, sans-serif;
    font-weight: bold;
}

h1 {
    font-size: 38px;
    line-height: 1.2;
}

h1.stylish-heading {
    margin-bottom: 60px;
    position: relative;

    &:before, &:after {
      position: absolute;
      top: -4px;
      left: 0;
      width: 66px;
      height: 1px;
      background: $text-color;
      content: '';
      display: block;
  }

  &:after {
    bottom: -14px;
    top: auto;
  }
}

h2 {
    font-size: 22px;
}
