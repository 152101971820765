.thank-you-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex: 1;

  h1 {
    margin-bottom: 10px;
  }

}
