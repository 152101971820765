.my-profile {

  label {
    display: block;
  }

  .form-control {
    margin-bottom: 30px;
  }

  input {
      border: 1px solid $text-color-light;
      padding: 16px 10px;
      border-radius: 5px;
      width: 66.6%;
      font-size: 14px;
  }

  .my-profile-button {
      background: $text-color;
      color: $white;
      border-radius: 5px;
      padding: 12px 50px;

      &:hover {
          background: lighten($text-color, 10%);
      }
  }
}
