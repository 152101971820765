.search-results-container-algolia {
    min-height: 400px;
    margin: 40px 0;
    display: grid;
    grid-template-columns: 3fr 7fr;
    grid-gap: 20px;
}

.ais-hits--item {
    .instantsearch-result {
        display:flex;
        align-items: center;

        img {
            margin-right: 40px;
        }
    }

    .result-details {
        color: $text-color-light;
    }

    .result-price {
        margin-top: 6px;
        font-weight: 500;
    }

    .algolia-thumb-result {
        max-height: 50px;
    }

    hr {
        border: 0.5px solid $separator-color;
    }
}

.ais-refinement-list--label {
    color: $text-color !important;
    font-size: 18px !important;
    display: flex;
    align-items: center;
}

.ais-refinement-list--item {
    margin-bottom: 12px;
}

.ais-refinement-list--count {
    color: $text-color !important;
    background: rgba(39,81,108,.2) !important;
    margin-left: auto;
    margin-right: 57px;
}
