.search-form {
    position: relative;
}

.search-icon {
    color: gray;
    position: absolute;
    top: 12px;
    left: 12px;
}

.search-box {
    padding: 10px 12px 10px 34px;
    width: 300px;
    max-width: 100%;
    font-size: 14px;
}
