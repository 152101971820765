.might-like-section {
    padding: 40px 0 70px;
    background: $light-gray-background;

    h2 {
        padding-bottom: 30px;
    }

    .might-like-grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 30px;
    }

    .might-like-product {
        border: 1px solid #979797;
        background: white;
        padding: 30px 0 20px;
        text-align: center;

        img {
            width: 70%;
        }
    }

    .might-like-product-price {
        color: $text-color-light;
    }
}
