footer {
    background: $gray-background;
    color: $white;
    padding: 40px 0;
    text-align: center;
  }

  .footer-content {
    display: flex;
    justify-content: space-between;

    .heart {
      color: $heart;
    }

    ul {
      display: flex;
      width: 30%;
      justify-content: space-between;
    }

    a {
      color: $white;
    }
  }
