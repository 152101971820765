$text-color: #212121;
$text-color-light: #919191;
$white: #e9e9e9;
$gray-background: #535353;
$light-gray-background: #F5F5F5;
$separator-color: #CDCDCD;
$heart: #FFBABA;
$primary: #3EBFA4;
$cart-count: #FFD94D;

$gutter: 30px;

