.breadcrumbs {
    background: $light-gray-background;
    border-bottom: 1px solid $separator-color;
    padding: 24px 0;

    .breadcrumb-separator {
        font-size: 14px;
        color: lighten($text-color, 20%);
    }

    .breadcrumbs-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .subsidiary {
        select {
            padding: 4px 10px;;
            margin: 10px 0;
        }
    }
}
